import React from 'react'
import './Itemcard.css'

export default function Itemcard({heading, content}) {
    console.log(heading)
    return (
        <div className="itemcard">
            <h1>{heading} </h1>
            <h5>{content} </h5>
        </div>
    )
}
