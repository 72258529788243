import React, {useState} from 'react'
import './App.css';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import Navbar from './components/Navbar';
import Sidebar from './components/Sidebar';
import Home from './pages/Home';
import Footer from './components/Footer';
import Service from './pages/Service';
import Login from './components/Login';

function App() {
  const [contactForm, setContactForm] = useState(false)
  return (
    <Router >
      <div className="App">
        <Sidebar/>
        <Navbar/>
        {contactForm && <Login setContactForm={setContactForm}/>}
        {/* <Login/> */}
        <Switch>
          <Route exact path="/"> <Home setContactForm={setContactForm}/> </Route>
          <Route path="/service1"> <Service serviceNo={1}/> </Route>
          <Route path="/service2"> <Service serviceNo={2}/> </Route>
          <Route path="/service3"> <Service serviceNo={3}/> </Route>
          <Route path="/service4"> <Service serviceNo={4}/> </Route>
            
        </Switch>
        <Footer/>
      </div>
    </Router>
  );
}

export default App;
