import React from 'react';
import ManageItem from '../components/ManageItem';
import './Home.css';

import card1 from '../assets/images/home1.png';
import card2 from '../assets/images/home2.png';
import card3 from '../assets/images/home3.png';
import card4 from '../assets/images/home4.png';

import TwitterIcon from '@material-ui/icons/Twitter';
import FacebookIcon from '@material-ui/icons/Facebook';

import aboutBlaze from '../assets/images/about blaze.png';

export default function Home({ setContactForm }) {
  return (
    <div className='homeMain' id='home'>
      <div className='manageCarts'>
        <ManageItem
          image={card4}
          text='Workforce Solutions'
          linkURL={'service4'}
        />
        <ManageItem
          image={card1}
          text='Managed Services'
          linkURL={'service1'}
        />
        <ManageItem
          image={card2}
          text='Management Consultant'
          linkURL={'service2'}
        />
        <ManageItem image={card3} text='Development' linkURL={'service3'} />
      </div>
      <div className='ourServices' id='serviceHome'>
        <div>
          <div className='serviceHeading'>
            <div></div>
            <h1>Our Services</h1>
          </div>
          <div>
            We enable companies to achieve world-class business performance.
          </div>
          {/* <button>More Info</button> */}
        </div>
        <div>
          <span>
            Blaze IT services are designed to generate increased efficiency and
            business value in key functional areas such as Finance, Information
            Technology, Supply Chain Management, Pharmaceutical, Healthcare and
            CRM. Our highly experienced consultants have successfully executed
            projects in major fortune 100 companies.
          </span>
        </div>
      </div>
      <div className='about' id='about'>
        <div>
          <img src={aboutBlaze} alt='' />
        </div>
        <div>
          <div className='aboutHeading'>
            <h1>About Blaze</h1> <div></div>
          </div>
          <div style={{ textAlign: 'left' }}>
            Blaze IT Consulting. is a leading business and technology consulting
            firm
          </div>
          <div className='aboutText'>
            Blaze IT Consulting is a pioneer in providing recruiting & IT
            solutions for businesses of all sizes & sectors which helps them
            enable scalable operations across industries.
            <br />
            The IT staffing solutions offered by Blaze contribute to improved
            efficiency and value in key areas such as Finance, Information
            Technology, Supply Chain Management, Pharmaceuticals, Healthcare,
            and Customer Relationship Management. With more than a decade of
            experience in Fortune 100 companies, we have completed dozens of
            projects. Based in Canada and the US Blaze IT Consulting has the
            staffing expertise and recruiting resources to help you hire highly
            skilled IT, technical, developer, systems and engineering talent,
            even in the competitive market of Toronto and GTA The expertise we
            have in recruiting software developers and IT infrastructure sets us
            apart from other companies. Using AI, machine learning, big data,
            and cloud computing, Blaze IT Consulting and recruitment pros assist
            clients in finding high-quality talent. We offer the most
            comprehensive IT staffing solution in the industry.
            <br />
            We are a trusted partner for growing, mid-sized, and enterprise
            organizations that allows them to achieve their business goals to
            scale operations, reduce risk, and get a consistent level of
            service.
          </div>
        </div>
      </div>
      <div className='CarCont'>
        <div className='career' id='career'>
          <div
            className='carbar'
            style={{ transform: 'translateX(-2px)' }}
          ></div>
          <div className='carContent content'>
            <h1>Career</h1>
            <h6 style={{ fontSize: '2.1vh' }}>
              We have spent the last few years pushing the limits of IT
              providers and managed service providers. We keep our focus on
              providing a world-class experience to companies that are often
              ignored by big businesses. Our goal is to build successful careers
              at Blaze Consultant by attracting the best talent, the finest
              specialists, and the most hardworking employees.
              <br />
              Blaze Consultant welcomes you regardless of your career
              aspirations, career change, or desire to make a difference with
              your experience. We are committed to providing our employees with
              education, training, and the support they need, so they can
              compete and succeed in the marketplace, making a positive impact
              on our customers.
            </h6>
            <div>
              {/* <button>
                                Know More
                            </button> */}
            </div>
          </div>
        </div>
        <div className='contact' id='contact'>
          <div className='conbar'></div>
          <div className='conContent content'>
            <h1>Contact Us</h1>
            <h6>
              705 Sunshine Court
              <br />
              Parlin, New Jersey
              <br />
              USA.
              <br /> +1-732-614-8503
            </h6>
            <div>
              <button onClick={() => setContactForm(true)}>Get In Touch</button>
              <a
                style={{ marginLeft: 20, color: '#ffffff', fontSize: 30 }}
                href='http://www.facebook.com/sharer.php?u=http%3A%2F%2Fblaze-consulting.com&t=BLAZE%20CONSULTING'
              >
                <FacebookIcon />
              </a>
              <a
                style={{ marginLeft: 20, color: '#ffffff', fontSize: 30 }}
                href='http://twitter.com/intent/tweet?text=Check%20out%20this%20GoDaddy%20hosted%20webpage!%20http%3A%2F%2Fblaze-consulting.com.'
              >
                <TwitterIcon />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
