import React from 'react'
import { HashLink } from 'react-router-hash-link'
import './Dropdown.css'

export default function Dropdown({dropped}) {
    const scrollWidthOffset = (el) => {
        const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
        const yOffset = -200; 
        window.scrollTo({ top: yCoordinate + yOffset}); 
    }
    return (
        <div className={"Dropdown " + (dropped && "dropActive")}>
            <HashLink to={"/service4#service"} style={{textDecoration: 'none'}}><h2 className="dropLink" scroll={el => scrollWidthOffset(el)}>Workforce Solutions</h2></HashLink>
            <HashLink to={"/service1#service"} style={{textDecoration: 'none'}}><h2 className="dropLink" scroll={el => scrollWidthOffset(el)}>Managed Services</h2></HashLink>
            <HashLink to={"/service2#service"} style={{textDecoration: 'none'}}><h2 className="dropLink" scroll={el => scrollWidthOffset(el)}>Management Consultant</h2></HashLink>
            <HashLink to={"/service3#service"} style={{textDecoration: 'none'}}><h2 className="dropLink" scroll={el => scrollWidthOffset(el)}>Development</h2></HashLink>
        </div>
    )
}
