import React from 'react'
import { HashLink } from 'react-router-hash-link'
import { Link as ScrollLink } from "react-scroll";
import './Sidebar.css'

export default function Sidebar() {
    const scrollWidthOffset = (el) => {
        const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
        const yOffset = -80; 
        window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' }); 
    }
    return (
        <div className='sidebarMain'>
            <div className="sideLinks">
                <ScrollLink
                    activeClass="active"
                    to="home"
                    spy={true}
                    smooth={true}
                    offset={-100}
                    duration={900}
                    >
                    <div className="sidelink"><HashLink to="/#home" style={{textDecoration: 'none', color: '#000'}} scroll={el => scrollWidthOffset(el)}> Home </HashLink></div>
                </ScrollLink>
                <ScrollLink
                    activeClass="reached"
                    to="serviceHome"
                    spy={true}
                    smooth={true}
                    offset={-100}
                    duration={900}
                    >
                    <div className="sidelink"><HashLink to="/#serviceHome" style={{textDecoration: 'none', color: '#000'}} scroll={el => scrollWidthOffset(el)}> Our Services </HashLink></div>
                </ScrollLink>
                <ScrollLink
                    activeClass="active"
                    to="about"
                    spy={true}
                    smooth={true}
                    offset={-100}
                    duration={900}
                    >
                    <div className="sidelink"><HashLink to="/#about" style={{textDecoration: 'none', color: '#000'}} scroll={el => scrollWidthOffset(el)}> About Blaze </HashLink></div>
                </ScrollLink>
                <ScrollLink
                    activeClass="active"
                    to="career"
                    spy={true}
                    smooth={true}
                    offset={-100}
                    duration={900}
                    >
                    <div className="sidelink"><HashLink to="/#career" style={{textDecoration: 'none', color: '#000'}} scroll={el => scrollWidthOffset(el)}> Career </HashLink></div>
                </ScrollLink>
                <ScrollLink
                    activeClass="active"
                    to="contact"
                    spy={true}
                    smooth={true}
                    offset={-100}
                    duration={900}
                    >
                    <div className="sidelink"><HashLink to="/#contact" style={{textDecoration: 'none', color: '#000'}} scroll={el => scrollWidthOffset(el)}> Contact Us </HashLink></div>
                </ScrollLink>
            </div>
        </div>
    )
}
