import React, { useState } from 'react'
import './Navbar.css'
// import { Link } from 'react-router-dom'
import { HashLink } from 'react-router-hash-link'
import { Link as ScrollLink } from "react-scroll";

import logo from '../assets/images/logo.png'
import Dropdown from './Dropdown'

export default function Navbar() {
    const [dropped ,setDropped] = useState(false)

    const scrollWidthOffset = (el) => {
        const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
        const yOffset = -80; 
        window.scrollTo({ top: yCoordinate + yOffset}); 
    }
    return (
        <div className="navMain">
            <div className="logoContainer">
            <ScrollLink
                    activeClass="active"
                    to="home"
                    spy={true}
                    smooth={true}
                    offset={-50}
                    duration={600}
                    >
                        <HashLink to="/#home" >
                        <img className="logo" src={logo} alt=""  scroll={el => scrollWidthOffset(el)}/>

                        </HashLink>
            </ScrollLink>
            </div>
            <div className="navLinks">
                <ScrollLink
                    activeClass="active"
                    to="home"
                    spy={true}
                    smooth={true}
                    offset={-100}
                    duration={600}
                    >
                    <HashLink className="link" to="/#home" scroll={el => scrollWidthOffset(el)} >Home</HashLink>
                </ScrollLink>
                <ScrollLink
                    activeClass="active"
                    to="serviceHome"
                    spy={true}
                    smooth={true}
                    offset={-100}
                    duration={600}
                    >
                        <HashLink className="link" to="/#serviceHome" onMouseEnter={()=>setDropped(true)} onMouseLeave={()=>setDropped(false)}  scroll={el => scrollWidthOffset(el)}> Our Services
                    <Dropdown dropped={dropped}/></HashLink>
                    
                </ScrollLink>
                <ScrollLink
                    activeClass="active"
                    to="about"
                    spy={true}
                    smooth={true}
                    offset={-100}
                    duration={600}
                    >
                    <HashLink className="link" to="/#about" scroll={el => scrollWidthOffset(el)}>About Us</HashLink>
                </ScrollLink>
                <ScrollLink
                    activeClass="active"
                    to="career"
                    spy={true}
                    smooth={true}
                    offset={-100}
                    duration={600}
                    >
                    <HashLink className="link" to="/#career" scroll={el => scrollWidthOffset(el)}>Career</HashLink>
                </ScrollLink>
                <ScrollLink
                    activeClass="active"
                    to="contact"
                    spy={true}
                    smooth={true}
                    offset={-100}
                    duration={600}
                    >
                    <HashLink className="link" to="/#contact" scroll={el => scrollWidthOffset(el)}>Contact Us</HashLink>
                </ScrollLink>
            </div>
        </div>
    )
}
