import React from 'react'
// import { Link } from 'react-router-dom'
import { HashLink } from 'react-router-hash-link'
import './ManageItem.css'

export default function ManageItem({image, text, linkURL}) {
    const scrollWidthOffset = (el) => {
        const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
        const yOffset = -200; 
        window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' }); 
    }
    return (
        <HashLink to={"/"+linkURL+"#service"} style={{textDecoration: 'none'}}  scroll={el => scrollWidthOffset(el)}>
            <div className="manageItem">
                <img src={image} alt="" />
                <h1>{text}</h1>
                <div></div>
            </div>
        </HashLink>
    )
}
