import React from 'react'
import './Footer.css'

import Logo from '../assets/images/logo2.png'
import { HashLink } from 'react-router-hash-link'

export default function Footer() {
    const scrollWidthOffset = (el) => {
        const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
        const yOffset = -200; 
        window.scrollTo({ top: yCoordinate + yOffset}); 
    }
    return (
        <div className="footer" id="footer">
            <div> <img src={Logo} alt="" /> </div>
            <div>
                <h1>Our Services</h1>
                <HashLink to={"/service1#service"} style={{textDecoration: 'none'}}  scroll={el => scrollWidthOffset(el)}>
                    <h6>Managed Services</h6>
                </HashLink> 
                <HashLink to={"/service2#service"} style={{textDecoration: 'none'}} scroll={el => scrollWidthOffset(el)}>
                    <h6>Management Consulting</h6>
                </HashLink> 
                <HashLink to={"/service3#service"} style={{textDecoration: 'none'}} scroll={el => scrollWidthOffset(el)}>
                    <h6>Development</h6>
                </HashLink> 
                <HashLink to={"/service4#service"} style={{textDecoration: 'none'}} scroll={el => scrollWidthOffset(el)}>
                    <h6>Workforce Solutions</h6>
                </HashLink> 
                
            </div>
            <div>
                {/* <h1>About Blaze</h1> */}
                {/* <h6>Where We Operate</h6> */}
                {/* <h6>Career</h6> */}
            </div>
            <div>
                {/* <h1>Contact Us</h1> */}
                {/* <h6>Phone</h6> */}
            </div>
        </div>
    )
}
