import React from 'react'
import './Login.css'
import { useForm, ValidationError } from '@formspree/react';

import CancelPresentationIcon from '@material-ui/icons/CancelPresentation';
import logo from '../assets/images/logo.png'

export default function Login({setContactForm}) {
    const [state, handleSubmit] = useForm("mjvjlvay");
  if (state.succeeded) {
      return <p>Thanks for joining!</p>;
  }
    return (
        <div className="login active">
            <div className="loginform">
                <div className="loginhead">
                    <h1>Fill in your details</h1>
                    <div onClick={()=> setContactForm(false)}>
                    <CancelPresentationIcon className="loginclose" />

                    </div>
                </div>
                <form onSubmit={handleSubmit} className="inputcontainer" >
                <input
        id="name"
        name="name"
        placeholder="Enter your name"
      /><input
      id="phone"
      name="phone"
      placeholder="Enter your phone number"
    />       
      <input
        id="email"
        type="email" 
        name="email"
        placeholder="Enter your email"
      />
      <ValidationError 
        prefix="Email" 
        field="email"
        errors={state.errors}
      />
      <input
        id="message"
        name="message"
        placeholder="How can we help you?"
      />
      <ValidationError 
        prefix="Message" 
        field="message"
        errors={state.errors}
      />
      <button className="submitbutton" type="submit" disabled={state.submitting}>
        Submit
      </button>
                </form>
                <div>
                    <img src={logo} alt="" />
                </div>
            </div>
        </div>
    )
}
