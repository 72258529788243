import React from 'react';
import { useEffect } from 'react';
import Itemcard from '../components/Itemcard';
import './Service.css';

export default function Service({ serviceNo }) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const cartList1 = [
    {
      heading: 'Cost-savings',
      content:
        'Move administrative responsibilities to a cost effective service mode',
    },
    {
      heading: 'Increased efficiency',
      content: 'Centralize and standardize administrative tasks',
    },
    {
      heading: 'Greater focus',
      content:
        'Allow specialized resources to focus on their areas of expertise with fewer distractions',
    },
    {
      heading: 'Workplace Satisfaction',
      content:
        'Specialized workers are spending more of their time doing what they enjoy',
    },
  ];
  const cartList2 = [
    {
      heading: 'Identify improvement areas',
      content: 'Identify weaker areas and implement strategies to improve them',
    },
    {
      heading: 'Leverage your strengths',
      content:
        'Highlight your strengths on a project level and apply them as best practices in your organization',
    },
    {
      heading: 'Build toward your goals',
      content:
        'Align your goals with metrics to measure your progress as you execute your implementation plan',
    },
  ];
  const cartList3 = [
    {
      heading: 'Cost-savings',
      content:
        'Move administrative responsibilities to a cost effective service mode',
    },
    {
      heading: 'Increased efficiency',
      content: 'Centralize and standardize administrative tasks',
    },
    {
      heading: 'Greater focus',
      content:
        'Allow specialized resources to focus on their areas of expertise with fewer distractions',
    },
    {
      heading: 'Workplace Satisfaction',
      content:
        'Specialized workers are spending more of their time doing what they enjoy',
    },
  ];

  return (
    <div className='serviceMain' id='service'>
      {serviceNo === 1 && (
        <>
          <div className='cardContainer'>
            {cartList1.map((item) => {
              return <Itemcard heading={item.heading} content={item.content} />;
            })}
          </div>
          <div className='serviceHeading'>
            <div></div>
            <h1>Managed Services</h1>
          </div>
          <div className='serviceContent'>
            Managed Communities facilitate scalability and consistency in
            projects by providing a flexible service model. With this solution,
            we can deliver long-term benefits to our clients, giving them the
            ability to build institutional knowledge that can be retained and
            leveraged across multiple projects.
            <br />
            Our goal is to provide the client with a staffing solution that
            meets their requirements. Apart from traditional staffing,
            contract-to-hire, and direct placement solutions, Blaze also prides
            itself on offering clients Managed Service options.
            <br />
            Having a strong and available bench of consultants to fill our
            client's needs allows us to provide Computer System Validation,
            Equipment Qualification, Quality Assurance, and Compliance Analysis
            services as managed services.
            <br />
            Utilizing Blaze Managed Services is an excellent way to benefit your
            company. These include:
            <ul>
              <li>
                Staff recruiting, hiring, and training efforts were reduced for
                Client management.
              </li>
              <li>The staff is knowledgeable and consistently high quality.</li>
              <li>
                Our resource models include project, portfolio, and
                time-and-motion.
              </li>
              <li>
                Taking responsibility for project delivery and execution
                together.
              </li>
              <li>
                Blaze’s knowledgeable management team is available to you.
              </li>
              <li>Models for predicting demand and capacity.</li>
              <li>Assist in managing resources.</li>
            </ul>
          </div>
        </>
      )}
      {serviceNo === 2 && (
        <>
          <div className='cardContainer'>
            {cartList2.map((item) => {
              return <Itemcard heading={item.heading} content={item.content} />;
            })}
          </div>
          <div className='serviceHeading'>
            <div></div>
            <h1>Management Consulting</h1>
          </div>
          <div className='serviceContent'>
            The consulting services offered by Blaze Management Consulting are
            designed to improve businesses' efficiencies and effectiveness by
            prioritizing and executing strategic projects. Together with our
            team, we design and develop comprehensive business solutions to
            tackle your organization's biggest challenges.
            <br />
            From conception through completion, Blaze Management Consulting
            works with your organization to assess the health and effectiveness
            of its project- and program management practices. In addition to
            identifying your individual and systemic strengths and weaknesses,
            we evaluate your processes. Regardless of our client's needs, we
            customize every management consulting engagement.
          </div>
        </>
      )}
      {serviceNo === 3 && (
        <>
          <div className='cardContainer'>
            {cartList3.map((item) => {
              return <Itemcard heading={item.heading} content={item.content} />;
            })}
          </div>
          <div className='serviceHeading'>
            <div></div>
            <h1>Development</h1>
          </div>
          <div className='serviceContent'>
            Our professional development programs will help you develop your
            skills in the workplace today. Blaze can assist you with everything
            from Mental Health First Aid to Effective Decision Making to
            developing your company's Emotional Intelligence. Today, companies
            and individuals need to stay current to succeed tomorrow. We offer
            flexible training options in e-learning, webinars, and in-person
            services, from leadership training to team training to professional
            development.
          </div>
        </>
      )}
      {serviceNo === 4 && (
        <>
          <div className='cardContainer'>
            {cartList3.map((item) => {
              return <Itemcard heading={item.heading} content={item.content} />;
            })}
          </div>
          <div className='serviceHeading'>
            <div></div>
            <h1>Workforce Solution</h1>
          </div>
          <div className='serviceContent'>
            A proper workforce strategy is essential to ensuring your business
            is prepared for consistent and continued growth. Blaze It Consulting
            is dedicated to making sure your organization is prepared for every
            phase of human resources by providing expert guidance, proven
            strategies, and management programs.
            <br />
            Your Blaze It consultant will assess your organization's
            opportunities in the following areas:
            <ul>
              <li>
                Managing talent - ensuring current and future demands are met
                and examined in the staffing plan.
              </li>
              <li>
                Evaluation of the efficiency of the functions of your
                organization and development of a plan for process improvement
                and benchmarks for best practices.
              </li>
              <li>
                An organization's design and structure are evaluated to identify
                processes, reporting frameworks, and job roles that can be
                improved for productivity improvement
              </li>
              <li>
                Integrate continuous development initiatives into the daily
                operations of your organization to attract and retain top
                talent. A learning model is often included in this process.
              </li>
            </ul>
          </div>
        </>
      )}
    </div>
  );
}
